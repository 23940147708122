<template>
	<main class="courses-create">
		<h1>Create course</h1>

		<form @submit.prevent="createCourse">
			<label for="name">Name</label>
			<input id="name" v-model="name" type="text" autocomplete="off" />

			<BaseButton type="submit">
				Create
			</BaseButton>
		</form>
	</main>
</template>

<script>
	import Backend from '../../inc/backend';
	import BaseButton from '../../components/BaseButton.vue';
	import Router from '../../inc/router';
	import Store from '../../inc/store';
	import {roleGuard} from '../../inc/auth';

	export default {
		...roleGuard('Courses', 'any', 'teacher'),
		components: {
			BaseButton
		},
		data() {
			return {
				name: '',
				showFeedback: ''
			};
		},
		methods: {
			createCourse() {
				Backend.post('courses', {
					name: this.name,
					meta: {
						showFeedback: this.showFeedback
					}
				}).then(res => {
					Router.push({name: 'Course', params: {courseId: res.data.id}});
					Store.editMode = true;
				});
			}
		}
	};
</script>